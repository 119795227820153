const configurationService = {
  getBaseUrl() {
    return (window as any)?.BASE_API_URL
  },
  getApplicationTitle() {
    const configTitle = (window as any)?.appTitle

    return !!configTitle ? configTitle : 'Assets Center'
  }
}

export default configurationService
