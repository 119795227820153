import { useNavigate } from 'react-router-dom'
import UrlNavigationService from '../../services/url-service'
import { useState, useEffect } from 'react'
import { alertRuleApiFactory } from '../../api-client/factory'
import {
  GetAllAlertRulesQueryResponseItem,
  GetAllAlertRulesQueryResponse
} from '../../api-client/generated'
import { Button } from 'react-bootstrap'
import { showSuccessToastr } from '../../services/toastr-notification-service'
import BaseListPage from '../Common/Base/BaseListPage/BaseListPage'
import { mapNamesToAgGridColumns } from '../../extensions/AgGridExtensions/AgGridColumns/ColumnsExtensions'
import getPropertiesFromObject from '../../extensions/getPropertiesFromObject'
import { ColDef } from 'ag-grid-community'
import { addColumnWithButton } from '../../extensions/AgGridExtensions/AgGridColumns/AgGridCustomColumns'

const AlertRules = () => {
  const navigate = useNavigate()

  const [rows, setRows] = useState<GetAllAlertRulesQueryResponseItem[]>([])
  const [columns, setColumns] = useState<ColDef[]>([])

  useEffect(() => {
    refreshData()
  }, [])

  const refreshData = () => {
    alertRuleApiFactory()
      .apiAlertRuleRulesGet()
      .then((response: GetAllAlertRulesQueryResponse) => {
        setRows(response!.items!)

        const firstItem = response.items![0]

        const names = getPropertiesFromObject(firstItem)
        const cols = mapNamesToAgGridColumns(names, ['alertRuleId', 'ruleTypeId', 'assetTypeId', 'resourceId'])

        const buttonCol = addColumnWithButton('Remove', (row) => removeAlertRule(row.name, row.alertRuleId))
        cols.push(buttonCol)

        setColumns(cols)
      })
  }

  const handleAddNewRoleBtn = () => {
    navigate(UrlNavigationService.addAlertRule())
  }

  const removeAlertRule = (name: string, alertRuleId: number) => {
    const confirmed = confirm(`Are sure to remove ${name}`)
    if (confirmed) {
      const params = {
        id: alertRuleId
      }

      alertRuleApiFactory()
        .apiAlertRuleRulesIdDelete(params)
        .then(() => {
          showSuccessToastr('Alert rule removed')
          refreshData()
        })
    }
  }

  const renderTable = () => {
    if (rows.length > 0) {
      return <div className="ag-theme-alpine" style={{ width: '100%', height: '75vh' }}>
        <BaseListPage
          title="Alert rules"
          statusDate={new Date()}
          columnsDef={columns}
          rows={rows}
        />
      </div>
    }

    return <span>No alert rules defined</span>
  }

  return (
    <div>
      <div>
        <Button onClick={handleAddNewRoleBtn}>Add</Button>
      </div>

      {renderTable()}
    </div>
  )
}

export default AlertRules
