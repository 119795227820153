import { ColDef, IRowNode } from 'ag-grid-community'
import AgGridCellButton from '../AgGridCells/AgGridCellButton'
import AgGridCellDiff from '../AgGridCells/AgGridCellDiff'
import AgGridCellLink from '../AgGridCells/AgGridCellLink'
import AgGridDateQuarter from '../AgGridCells/AgGridDateQuarter'
import AgGridTextWithButton from '../AgGridCells/AgGridTextWithButton'
import { formatDate } from '../../../services/culture-service'

export const addColumnWithButton = (
  name: string,
  onClick: (param: any) => void,
  title?: string
): ColDef => {
  return {
    field: name,
    cellRenderer: AgGridCellButton,
    cellRendererParams: {
      title: !!title ? title : name,
      clicked: (param: any) => {
        onClick(param)
      }
    }
  }
}

export const addDiffValueAndPercent = (name: string): ColDef => {
  return {
    field: name,
    comparator: (
      valueA: any,
      valueB: any,
      nodeA: IRowNode<any>,
      nodeB: IRowNode<any>,
      isDescending: boolean
    ) => {
      const a1 = nodeA.data.currentValue
      const b1 = nodeA.data.prevValues[0].value
      const firstGainPercent = (a1 - b1) / a1

      const a2 = nodeB.data.currentValue
      const b2 = nodeB.data.prevValues[0].value
      const secondGainPercent = (a2 - b2) / a2

      if (isDescending) {
        return firstGainPercent > secondGainPercent ? 1 : -1
      } else {
        return firstGainPercent > secondGainPercent ? 1 : -1
      }
    },
    filter: 'agNumberColumnFilter',
    sortable: true,
    cellRenderer: AgGridCellDiff,
    cellRendererParams: {
      getCurrentValue: (props: any) => {
        return props.data.currentValue
      },
      getPrevValue: (props: any) => {
        return props.data.prevValues.length > 0
          ? props.data.prevValues[0].value
          : undefined
      }
    }
  }
}

export const addLinkColumn = (
  name: string,
  linkName: string,
  getUrl: (a: any) => string,
  sortable: boolean = true,
  getLinkName?: (a: any) => string
): ColDef => {
  return {
    sortable,
    field: name,
    cellRenderer: AgGridCellLink,
    cellRendererParams: {
      getUrl,
      linkName,
      getLinkName
    }
  }
}

// todo
export const addDateQuarterColumn = (
  name: string,
  year: number,
  quarter: any
): ColDef => {
  return {
    field: name,
    cellRenderer: AgGridDateQuarter,
    cellRendererParams: {
      year,
      quarter
    }
  }
}

export const addTextWithButtonColumn = (
  propertyName: string,
  idPropertyName: string,
  onclick: (id: string, propertyName: string) => void
): ColDef => {
  return {
    field: propertyName,
    sortable: true,
    cellRenderer: AgGridTextWithButton,
    cellRendererParams: {
      onClick: (props: any) => {
        const id = props.data[idPropertyName]
        onclick(id, propertyName)
      },
      getText: (props: any) => {
        const value = props.data[propertyName]
        if (!value) {
          return '-'
        }

        if (value instanceof Date) {
          return formatDate(value)
        }

        return value
      }
    }
  }
}
