import DateRangeComponent from '../DateRange'
import { mapToDateRange, PredefinedDateRangeEnum } from '../../DateRange/PredefinedDateRangeEnum'
import { useState } from 'react'
import styles from './DateRangePredefined.module.scss'

interface Props {
  initStartDate?: Date,
  initEndDate?: Date,
  initPredefinedDateRange?: PredefinedDateRangeEnum
  disabled?: boolean,
  onChange?: (startDate?: Date, endDate?: Date, predefinedDateRange?: PredefinedDateRangeEnum) => void
}

const DateRangePredefined = (props: Props) => {
  const [selectedPredefinedDateRange, setSelectedPredefinedDateRange] = useState<PredefinedDateRangeEnum | undefined>(props.initPredefinedDateRange)
  const [startDate, setStartDate] = useState<Date | undefined>(props.initStartDate)
  const [endDate, setEndDate] = useState<Date | undefined>(props.initEndDate)

  const isDisabled = () => {
    return props.disabled || selectedPredefinedDateRange !== PredefinedDateRangeEnum.Custom
  }

  const setDateRange = (param: PredefinedDateRangeEnum) => {
    const range = mapToDateRange(param)

    setSelectedPredefinedDateRange(param)
    setStartDate(range?.startDate)
    setEndDate(range?.endDate)

    props.onChange?.(range?.startDate, range?.endDate, param)
  }

  const onChangeInternal = (startDate?: Date, endDate?: Date) => {
    setStartDate(startDate)
    setEndDate(endDate)

    props.onChange?.(startDate, endDate, PredefinedDateRangeEnum.Custom)
  }

  const getButtonCssClass = (range: PredefinedDateRangeEnum) => {
    return range === selectedPredefinedDateRange
      ? styles.activeButton
      : ''
  }

  return <div>
    <DateRangeComponent
      startDate={startDate}
      endDate={endDate}
      disabled={isDisabled()}
      onChange={onChangeInternal}/>
    <div className={styles.mHalf}>
      <button className={getButtonCssClass(PredefinedDateRangeEnum.Month)} onClick={() => setDateRange(PredefinedDateRangeEnum.Month)}>M</button>
      <button className={getButtonCssClass(PredefinedDateRangeEnum.Quarter)} onClick={() => setDateRange(PredefinedDateRangeEnum.Quarter)}>Q</button>
      <button className={getButtonCssClass(PredefinedDateRangeEnum.Year)} onClick={() => setDateRange(PredefinedDateRangeEnum.Year)}>1Y</button>
      <button className={getButtonCssClass(PredefinedDateRangeEnum.TwoYears)} onClick={() => setDateRange(PredefinedDateRangeEnum.TwoYears)}>2Y</button>
      <button className={getButtonCssClass(PredefinedDateRangeEnum.ThreeYears)} onClick={() => setDateRange(PredefinedDateRangeEnum.ThreeYears)}>3Y</button>
      <button className={getButtonCssClass(PredefinedDateRangeEnum.FiverYears)} onClick={() => setDateRange(PredefinedDateRangeEnum.FiverYears)}>5Y</button>
      <button className={getButtonCssClass(PredefinedDateRangeEnum.Custom)} onClick={() => setDateRange(PredefinedDateRangeEnum.Custom)}>Custom</button>
      <button className={getButtonCssClass(PredefinedDateRangeEnum.None)} onClick={() => setDateRange(PredefinedDateRangeEnum.None)}>None</button>
    </div>
  </div>
}

export default DateRangePredefined
