/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertRuleTypeEnum,
    AlertRuleTypeEnumFromJSON,
    AlertRuleTypeEnumFromJSONTyped,
    AlertRuleTypeEnumToJSON,
} from './AlertRuleTypeEnum';

/**
 * 
 * @export
 * @interface AlertRuleTypeModel
 */
export interface AlertRuleTypeModel {
    /**
     * 
     * @type {number}
     * @memberof AlertRuleTypeModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AlertRuleTypeModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AlertRuleTypeModel
     */
    description?: string | null;
    /**
     * 
     * @type {AlertRuleTypeEnum}
     * @memberof AlertRuleTypeModel
     */
    alertRuleType?: AlertRuleTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AlertRuleTypeModel
     */
    alertRuleTypeName?: string | null;
}

export function AlertRuleTypeModelFromJSON(json: any): AlertRuleTypeModel {
    return AlertRuleTypeModelFromJSONTyped(json, false);
}

export function AlertRuleTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRuleTypeModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'alertRuleType': !exists(json, 'alertRuleType') ? undefined : AlertRuleTypeEnumFromJSON(json['alertRuleType']),
        'alertRuleTypeName': !exists(json, 'alertRuleTypeName') ? undefined : json['alertRuleTypeName'],
    };
}

export function AlertRuleTypeModelToJSON(value?: AlertRuleTypeModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'alertRuleType': AlertRuleTypeEnumToJSON(value.alertRuleType),
        'alertRuleTypeName': value.alertRuleTypeName,
    };
}

