/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GeneralImportStatuses,
    GeneralImportStatusesFromJSON,
    GeneralImportStatusesFromJSONTyped,
    GeneralImportStatusesToJSON,
} from './GeneralImportStatuses';

/**
 * 
 * @export
 * @interface StackImportStatuses
 */
export interface StackImportStatuses {
    /**
     * 
     * @type {string}
     * @memberof StackImportStatuses
     */
    companyShortCode?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof StackImportStatuses
     */
    lastDividendImportDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof StackImportStatuses
     */
    lastFinancialDataImportDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StackImportStatuses
     */
    lastIndicatorImportDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StackImportStatuses
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StackImportStatuses
     */
    name?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof StackImportStatuses
     */
    lastQuotationDate?: Date | null;
}

export function StackImportStatusesFromJSON(json: any): StackImportStatuses {
    return StackImportStatusesFromJSONTyped(json, false);
}

export function StackImportStatusesFromJSONTyped(json: any, ignoreDiscriminator: boolean): StackImportStatuses {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyShortCode': !exists(json, 'companyShortCode') ? undefined : json['companyShortCode'],
        'lastDividendImportDate': !exists(json, 'lastDividendImportDate') ? undefined : (json['lastDividendImportDate'] === null ? null : new Date(json['lastDividendImportDate'])),
        'lastFinancialDataImportDate': !exists(json, 'lastFinancialDataImportDate') ? undefined : json['lastFinancialDataImportDate'],
        'lastIndicatorImportDate': !exists(json, 'lastIndicatorImportDate') ? undefined : json['lastIndicatorImportDate'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'lastQuotationDate': !exists(json, 'lastQuotationDate') ? undefined : (json['lastQuotationDate'] === null ? null : new Date(json['lastQuotationDate'])),
    };
}

export function StackImportStatusesToJSON(value?: StackImportStatuses | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyShortCode': value.companyShortCode,
        'lastDividendImportDate': value.lastDividendImportDate === undefined ? undefined : (value.lastDividendImportDate === null ? null : value.lastDividendImportDate.toISOString()),
        'lastFinancialDataImportDate': value.lastFinancialDataImportDate,
        'lastIndicatorImportDate': value.lastIndicatorImportDate,
        'id': value.id,
        'name': value.name,
        'lastQuotationDate': value.lastQuotationDate === undefined ? undefined : (value.lastQuotationDate === null ? null : value.lastQuotationDate.toISOString()),
    };
}

