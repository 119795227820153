import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { currencyApiFactory } from '../../../../api-client/factory'
import { AssetTypeEnum, GetCurrentCurrencyDetailsQueryResponse, QuotesStats } from '../../../../api-client/generated'
import BaseDetailsPage from '../../../Common/Base/BaseDetailsPage/BaseDetailsPage'
import { PredefinedDateRangeEnum } from '../../../Common/DateRange/PredefinedDateRangeEnum'
import { DateTime } from 'luxon'

const CurrencyDetails = () => {
  const { id } = useParams()

  const [fromDate, setFromDate] = useState<Date | undefined>(DateTime.local().plus({ months: -3 }).toJSDate())
  const [toDate, setToDate] = useState<Date | undefined>(new Date())
  const [predefinedDateRange, setPredefinedDateRange] = useState<PredefinedDateRangeEnum | undefined>(PredefinedDateRangeEnum.Custom)

  const [rowData, setRowData] = useState<any[]>([])
  const [currencyName, setCurrencyName] = useState<string>('')
  const [quotesStats, setQuotesStats] = useState<QuotesStats>()

  const dateRangeChanged = (startDate?: Date, endDate?: Date) => {
    setFromDate(startDate)
    setToDate(endDate)
    setPredefinedDateRange(predefinedDateRange)

    currencyApiFactory()
      .apiCurrencyCurrencyShortCodeGet({
        shortCode: id!,
        fromDate: startDate,
        toDate: endDate
      })
      .then((response: GetCurrentCurrencyDetailsQueryResponse) => {
        setRowData(response.items!)
        setCurrencyName(response.currencyName!)

        setQuotesStats(response.queryStats)
      })
  }

  const getIndexValue = (item: any) => {
    return Number(item.buyValue)
  }

  return (
    <BaseDetailsPage
      fromDate={fromDate}
      toDate={toDate}
      predefinedDateRange={predefinedDateRange}
      items={rowData}
      resourceType={AssetTypeEnum.Currency}
      quotesStats={quotesStats}
      resourceId={id!}
      summaryComponent={<h1>{currencyName}</h1>}
      dateRangeChanged={dateRangeChanged}
      getValue={getIndexValue}
    />
  )
}

export default CurrencyDetails
