/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';
import {
    CompanyDividendStatusEnum,
    CompanyDividendStatusEnumFromJSON,
    CompanyDividendStatusEnumFromJSONTyped,
    CompanyDividendStatusEnumToJSON,
} from './CompanyDividendStatusEnum';

/**
 * 
 * @export
 * @interface CompanyDividend
 */
export interface CompanyDividend {
    /**
     * 
     * @type {number}
     * @memberof CompanyDividend
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof CompanyDividend
     */
    created?: Date;
    /**
     * 
     * @type {number}
     * @memberof CompanyDividend
     */
    companyId?: number;
    /**
     * 
     * @type {Company}
     * @memberof CompanyDividend
     */
    company?: Company;
    /**
     * 
     * @type {number}
     * @memberof CompanyDividend
     */
    year?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyDividend
     */
    dividendValue?: number;
    /**
     * 
     * @type {Date}
     * @memberof CompanyDividend
     */
    dividendPayDate?: Date | null;
    /**
     * 
     * @type {CompanyDividendStatusEnum}
     * @memberof CompanyDividend
     */
    status?: CompanyDividendStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof CompanyDividend
     */
    lawToDividendDate?: Date | null;
}

export function CompanyDividendFromJSON(json: any): CompanyDividend {
    return CompanyDividendFromJSONTyped(json, false);
}

export function CompanyDividendFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyDividend {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'company': !exists(json, 'company') ? undefined : CompanyFromJSON(json['company']),
        'year': !exists(json, 'year') ? undefined : json['year'],
        'dividendValue': !exists(json, 'dividendValue') ? undefined : json['dividendValue'],
        'dividendPayDate': !exists(json, 'dividendPayDate') ? undefined : (json['dividendPayDate'] === null ? null : new Date(json['dividendPayDate'])),
        'status': !exists(json, 'status') ? undefined : CompanyDividendStatusEnumFromJSON(json['status']),
        'lawToDividendDate': !exists(json, 'lawToDividendDate') ? undefined : (json['lawToDividendDate'] === null ? null : new Date(json['lawToDividendDate'])),
    };
}

export function CompanyDividendToJSON(value?: CompanyDividend | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'companyId': value.companyId,
        'company': CompanyToJSON(value.company),
        'year': value.year,
        'dividendValue': value.dividendValue,
        'dividendPayDate': value.dividendPayDate === undefined ? undefined : (value.dividendPayDate === null ? null : value.dividendPayDate.toISOString()),
        'status': CompanyDividendStatusEnumToJSON(value.status),
        'lawToDividendDate': value.lawToDividendDate === undefined ? undefined : (value.lawToDividendDate === null ? null : value.lawToDividendDate.toISOString()),
    };
}

