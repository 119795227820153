import { ColDef } from 'ag-grid-community'
import { useEffect, useState } from 'react'
import {
  CompanyRateResult,
  GetTheMostRecommendedCompaniesQueryResponse
} from '../../../api-client/generated'
import BaseListPage from '../../Common/Base/BaseListPage/BaseListPage'
import { assistantApiFactory } from '../../../api-client/factory'
import { getAssistantTableColumns } from './AssistantTableColumns'

const Assistant = () => {
  const [rows, setRows] = useState<CompanyRateResult[]>([])
  const [cols, setCols] = useState<ColDef[]>([])

  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    const columns = getAssistantTableColumns()
    setCols(columns)

    setIsLoading(true)
    assistantApiFactory()
      .apiAssistantGetTheMostRecommendedCompaniesGet()
      .then((response: GetTheMostRecommendedCompaniesQueryResponse) => {
        setRows(response.items!)
      })
      .finally(() => setIsLoading(false))
  }, [])

  return isLoading
    ? <>Data is loading...</>
    : <>
        <BaseListPage
          title="Asystent"
          statusDate={new Date()}
          rows={rows}
          columnsDef={cols}
        />
      </>
}

export default Assistant
