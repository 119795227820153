import { AgGridReact } from 'ag-grid-react'
import { importStatusesApiFactory } from '../../api-client/factory'
import { useEffect, useState } from 'react'
import {
  AssetTypeEnum,
  GeneralImportStatuses,
  GetImportStatusesQueryResponse,
  StackImportStatuses
} from '../../api-client/generated'
import { ColDef } from 'ag-grid-community'
import {
  addLinkColumn,
  addTextWithButtonColumn
} from '../../extensions/AgGridExtensions/AgGridColumns/AgGridCustomColumns'
import {
  showSuccessToastr,
  showErrorToastr
} from '../../services/toastr-notification-service'
import './ImportStatuses.scss'
import UrlNavigationService from '../../services/url-service'
import ImportStatusPanel from './ImportStatus/ImportStatusPanel'

const ImportStatuses = () => {
  const [stockRows, setStockRows] = useState<StackImportStatuses[]>([])
  const [stockColDefs, setstockColDefs] = useState<ColDef[]>([])

  const [currencyRows, setCurrencyRows] = useState<GeneralImportStatuses[]>([])
  const [marketStatusRows, setMarketStatusRows] = useState<GeneralImportStatuses[]>([])
  const [nobleMetalsStatusRows, setNobleMetalsStatusRows] = useState<GeneralImportStatuses[]>([])

  const getStockUrl = (p: any) => {
    const id = Number(p.data.id)
    return UrlNavigationService.stockDetails(id)
  }

  const getLinkName = (p: any) => {
    return p?.data?.name
  }

  useEffect(() => {
    importStatusesApiFactory()
      .apiImportsGet()
      .then((resp: GetImportStatusesQueryResponse) => {
        const col = [
          {
            ...addLinkColumn(
              'name',
              '',
              (p) => getStockUrl(p),
              true,
              (p) => getLinkName(p)),
            sortable: true,
            filter: 'agTextColumnFilter'
          },
          {
            field: 'companyShortCode',
            sortable: true,
            filter: 'agTextColumnFilter'
          },
          addTextWithButtonColumn(
            'lastQuotationDate',
            'companyShortCode',
            runStockRefreshData
          ),
          addTextWithButtonColumn(
            'lastFinancialDataImportDate',
            'companyShortCode',
            runStockRefreshData
          ),
          addTextWithButtonColumn(
            'lastIndicatorImportDate',
            'companyShortCode',
            runStockRefreshData
          ),
          addTextWithButtonColumn(
            'lastDividendImportDate',
            'companyShortCode',
            runStockRefreshData
          )
        ]

        setCurrencyRows(resp.currencyImportStatuses!)
        setMarketStatusRows(resp.stacksMarketIndexImportStatuses!)
        setNobleMetalsStatusRows(resp.nobleMetalsImportStatuses!)

        setstockColDefs(col)
        setStockRows(resp.stacksImportStatuses!)
      })
  }, [])

  const runStockRefreshData = (id: string, propertyName: string) => {
    const param = {
      assetTypeEnum: AssetTypeEnum.Stock,
      id,
      propertyToRefresh: propertyName
    }

    importStatusesApiFactory()
      .apiImportsRunPost(param)
      .then(() => {
        showSuccessToastr('Imported successfully')
      })
      .catch(() => {
        showErrorToastr(`Cannot import ${propertyName} for ${id}`)
      })
  }

  return (
    <>
      <h1>Import statuses</h1>
      <div>
        <ImportStatusPanel currencyRows={currencyRows} marketIndexRows={marketStatusRows} nobleMetalRows={nobleMetalsStatusRows} />
      </div>
      <hr />
      <div>
        <h4>Stocks</h4>
        <div className="ag-theme-alpine" style={{ width: '100%', height: 500 }}>
          <AgGridReact columnDefs={stockColDefs} rowData={stockRows} />
        </div>
      </div>
    </>
  )
}

export default ImportStatuses
