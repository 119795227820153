/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TimestampedValue,
    TimestampedValueFromJSON,
    TimestampedValueFromJSONTyped,
    TimestampedValueToJSON,
} from './TimestampedValue';

/**
 * 
 * @export
 * @interface CompanyRatingDividendModel
 */
export interface CompanyRatingDividendModel {
    /**
     * 
     * @type {number}
     * @memberof CompanyRatingDividendModel
     */
    yearWithContinuePaidDividends?: number;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof CompanyRatingDividendModel
     */
    yearsWithDividends?: { [key: string]: boolean; } | null;
    /**
     * 
     * @type {TimestampedValue}
     * @memberof CompanyRatingDividendModel
     */
    lastDividend?: TimestampedValue;
    /**
     * 
     * @type {number}
     * @memberof CompanyRatingDividendModel
     */
    lastDividendQuotation?: number;
}

export function CompanyRatingDividendModelFromJSON(json: any): CompanyRatingDividendModel {
    return CompanyRatingDividendModelFromJSONTyped(json, false);
}

export function CompanyRatingDividendModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyRatingDividendModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'yearWithContinuePaidDividends': !exists(json, 'yearWithContinuePaidDividends') ? undefined : json['yearWithContinuePaidDividends'],
        'yearsWithDividends': !exists(json, 'yearsWithDividends') ? undefined : json['yearsWithDividends'],
        'lastDividend': !exists(json, 'lastDividend') ? undefined : TimestampedValueFromJSON(json['lastDividend']),
        'lastDividendQuotation': !exists(json, 'lastDividendQuotation') ? undefined : json['lastDividendQuotation'],
    };
}

export function CompanyRatingDividendModelToJSON(value?: CompanyRatingDividendModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'yearWithContinuePaidDividends': value.yearWithContinuePaidDividends,
        'yearsWithDividends': value.yearsWithDividends,
        'lastDividend': TimestampedValueToJSON(value.lastDividend),
        'lastDividendQuotation': value.lastDividendQuotation,
    };
}

