/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertRuleTypeEnum,
    AlertRuleTypeEnumFromJSON,
    AlertRuleTypeEnumFromJSONTyped,
    AlertRuleTypeEnumToJSON,
} from './AlertRuleTypeEnum';
import {
    AssetTypeEnum,
    AssetTypeEnumFromJSON,
    AssetTypeEnumFromJSONTyped,
    AssetTypeEnumToJSON,
} from './AssetTypeEnum';
import {
    CreateAlertRuleQueryCondition,
    CreateAlertRuleQueryConditionFromJSON,
    CreateAlertRuleQueryConditionFromJSONTyped,
    CreateAlertRuleQueryConditionToJSON,
} from './CreateAlertRuleQueryCondition';

/**
 * 
 * @export
 * @interface CreateAlertRuleQuery
 */
export interface CreateAlertRuleQuery {
    /**
     * 
     * @type {string}
     * @memberof CreateAlertRuleQuery
     */
    name?: string | null;
    /**
     * 
     * @type {AlertRuleTypeEnum}
     * @memberof CreateAlertRuleQuery
     */
    alertRuleType?: AlertRuleTypeEnum;
    /**
     * 
     * @type {AssetTypeEnum}
     * @memberof CreateAlertRuleQuery
     */
    assetType?: AssetTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateAlertRuleQuery
     */
    resourceId?: number;
    /**
     * 
     * @type {CreateAlertRuleQueryCondition}
     * @memberof CreateAlertRuleQuery
     */
    conditions?: CreateAlertRuleQueryCondition;
}

export function CreateAlertRuleQueryFromJSON(json: any): CreateAlertRuleQuery {
    return CreateAlertRuleQueryFromJSONTyped(json, false);
}

export function CreateAlertRuleQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAlertRuleQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'alertRuleType': !exists(json, 'alertRuleType') ? undefined : AlertRuleTypeEnumFromJSON(json['alertRuleType']),
        'assetType': !exists(json, 'assetType') ? undefined : AssetTypeEnumFromJSON(json['assetType']),
        'resourceId': !exists(json, 'resourceId') ? undefined : json['resourceId'],
        'conditions': !exists(json, 'conditions') ? undefined : CreateAlertRuleQueryConditionFromJSON(json['conditions']),
    };
}

export function CreateAlertRuleQueryToJSON(value?: CreateAlertRuleQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'alertRuleType': AlertRuleTypeEnumToJSON(value.alertRuleType),
        'assetType': AssetTypeEnumToJSON(value.assetType),
        'resourceId': value.resourceId,
        'conditions': CreateAlertRuleQueryConditionToJSON(value.conditions),
    };
}

