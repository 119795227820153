/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AllCompanyDividendItem
 */
export interface AllCompanyDividendItem {
    /**
     * 
     * @type {Date}
     * @memberof AllCompanyDividendItem
     */
    created?: Date;
    /**
     * 
     * @type {number}
     * @memberof AllCompanyDividendItem
     */
    companyId?: number;
    /**
     * 
     * @type {string}
     * @memberof AllCompanyDividendItem
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllCompanyDividendItem
     */
    year?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AllCompanyDividendItem
     */
    dividendValue?: number;
    /**
     * 
     * @type {Date}
     * @memberof AllCompanyDividendItem
     */
    dividendPayDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof AllCompanyDividendItem
     */
    status?: string | null;
}

export function AllCompanyDividendItemFromJSON(json: any): AllCompanyDividendItem {
    return AllCompanyDividendItemFromJSONTyped(json, false);
}

export function AllCompanyDividendItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllCompanyDividendItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'year': !exists(json, 'year') ? undefined : json['year'],
        'dividendValue': !exists(json, 'dividendValue') ? undefined : json['dividendValue'],
        'dividendPayDate': !exists(json, 'dividendPayDate') ? undefined : (json['dividendPayDate'] === null ? null : new Date(json['dividendPayDate'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function AllCompanyDividendItemToJSON(value?: AllCompanyDividendItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'companyId': value.companyId,
        'companyName': value.companyName,
        'year': value.year,
        'dividendValue': value.dividendValue,
        'dividendPayDate': value.dividendPayDate === undefined ? undefined : (value.dividendPayDate === null ? null : value.dividendPayDate.toISOString()),
        'status': value.status,
    };
}

