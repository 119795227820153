import { ColDef } from 'ag-grid-community'
import { importStatusesApiFactory } from '../../../api-client/factory'
import { AssetTypeEnum, GeneralImportStatuses } from '../../../api-client/generated'
import { addLinkColumn } from '../../../extensions/AgGridExtensions/AgGridColumns/AgGridCustomColumns'
import {
  showErrorToastr,
  showSuccessToastr
} from '../../../services/toastr-notification-service'
import ImportStatusBox from './ImportStatusBox'
import UrlNavigationService from '../../../services/url-service'
import './ImportStatusPanel.scss'

interface Props {
  currencyRows: GeneralImportStatuses[]
  marketIndexRows: GeneralImportStatuses[]
  nobleMetalRows: GeneralImportStatuses[]
}

const ImportStatusPanel = (props: Props) => {
  const boxTableHeigth = 220
  const boxTableWidth = 450

  const currencyCols: ColDef[] = [
    addLinkColumn(
      'name',
      '',
      (p) => getCurrencyUrl(p),
      true,
      (p) => getLinkName(p)
    ),
    { field: 'lastQuotationDate' }
  ]

  const nobleMetalsStatusCols: ColDef[] = [
    addLinkColumn(
      'name',
      '',
      (p) => getNobleMetalUrl(p),
      true,
      (p) => getLinkName(p)
    ),
    { field: 'lastQuotationDate' }
  ]

  const marketStatusCols: ColDef[] = [
    addLinkColumn(
      'name',
      '',
      (p) => getMarketIndexkUrl(p),
      true,
      (p) => getLinkName(p)
    ),
    { field: 'lastQuotationDate' }
  ]

  const runCurrencyRefreshData = () => {
    importStatusesApiFactory()
      .apiImportsRunPost({
        assetTypeEnum: AssetTypeEnum.Currency
      })
      .then(() => {
        showSuccessToastr('Imported successfully')

        // showInfoToastr
      })
      .catch(() => {
        showErrorToastr('Cannot import currences quotations')
      })
  }

  const runMarketIndexesRefreshData = () => {
    importStatusesApiFactory()
      .apiImportsRunPost({
        assetTypeEnum: AssetTypeEnum.MarketIndex
      })
      .then(() => {
        showSuccessToastr('Imported successfully')

        // showInfoToastr
      })
      .catch(() => {
        showErrorToastr('Cannot import market index quotations')
      })
  }

  const runNobleMetalsRefreshData = () => {
    importStatusesApiFactory()
      .apiImportsRunPost({
        assetTypeEnum: AssetTypeEnum.NobleMetal
      })
      .then(() => {
        showSuccessToastr('Imported successfully')
      })
      .catch(() => {
        showErrorToastr('Cannot import noble metals quotations')
      })
  }

  const getMarketIndexkUrl = (p: any) => {
    return UrlNavigationService.marketIndexDetails(p.data.name)
  }

  const getCurrencyUrl = (p: any) => {
    const id = Number(p.data.id)

    return UrlNavigationService.currencyDetails(id)
  }

  const getNobleMetalUrl = (p: any) => {
    return UrlNavigationService.nobleMetalsDetails(p.data.id)
  }

  const getLinkName = (p: any) => {
    return p?.data?.name
  }

  return (
    <div>
      <ImportStatusBox
        title="Currencies"
        rows={props.currencyRows}
        columns={currencyCols}
        className='box'
        runRefreshData={runCurrencyRefreshData}
        boxTableHeigth={boxTableHeigth}
        boxTableWidth={boxTableWidth}
      />
      <ImportStatusBox
        title="Market Indexes"
        rows={props.marketIndexRows}
        columns={marketStatusCols}
        className='box'
        runRefreshData={runMarketIndexesRefreshData}
        boxTableHeigth={boxTableHeigth}
        boxTableWidth={boxTableWidth}
      />
      <ImportStatusBox
        title="Noble Metals"
        rows={props.nobleMetalRows}
        columns={nobleMetalsStatusCols}
        className='box'
        runRefreshData={runNobleMetalsRefreshData}
        boxTableHeigth={boxTableHeigth}
        boxTableWidth={boxTableWidth}
      />
    </div>
  )
}

export default ImportStatusPanel
