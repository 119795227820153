import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { stockApiFactory } from '../../../../api-client/factory'
import {
  AssetTypeEnum,
  CompanyBasicInfo,
  CompanyDividend,
  CompanyFinancialData,
  CompanyIndicator,
  CompanyRecommendationBasicInfo,
  GetCompanyDetailsResponse,
  QuotesStats,
  StockQuote
} from '../../../../api-client/generated'
import StockDetailsSummary from './StockDetailsSummary'
import BaseDetailsPage from '../../../Common/Base/BaseDetailsPage/BaseDetailsPage'
import { DateTime } from 'luxon'
import { PredefinedDateRangeEnum } from '../../../Common/DateRange/PredefinedDateRangeEnum'

const StockDetails = () => {
  const { id } = useParams()

  const [fromDate, setFromDate] = useState<Date | undefined>(DateTime.local().plus({ months: -3 }).toJSDate())
  const [toDate, setToDate] = useState<Date | undefined>(new Date())
  const [predefinedDateRange, setPredefinedDateRange] = useState<PredefinedDateRangeEnum | undefined>(PredefinedDateRangeEnum.Custom)

  const [isDataLoading, setIsDataLoading] = useState<boolean>(false)

  const [companyDetails, setCompanyDetails] = useState<CompanyBasicInfo>({})
  const [quotationItems, setQuotationItems] = useState<StockQuote[]>([])
  const [quotesStats, setQuotesStats] = useState<QuotesStats>()

  const [basicInfo, setBasicInfo] = useState<CompanyBasicInfo>({})

  const [indicators, setIndicators] = useState<CompanyIndicator>()
  const [financialData, setFinancialData] = useState<CompanyFinancialData>()

  const [dividend, setDividend] = useState<CompanyDividend>()
  const [recommendation, setRecommendation] = useState<CompanyRecommendationBasicInfo>()

  const dateRangeChanged = (startDate?: Date, endDate?: Date, predefinedDateRange?: PredefinedDateRangeEnum) => {
    setIsDataLoading(true)

    setFromDate(startDate)
    setToDate(endDate)
    setPredefinedDateRange(predefinedDateRange)

    stockApiFactory()
      .apiStockCompanyIdGet({
        companyId: Number(id),
        from: startDate,
        to: endDate
      })
      .then((resp: GetCompanyDetailsResponse) => {
        setBasicInfo(resp.basicInfo!)
        setCompanyDetails(resp.basicInfo!)

        setDividend(resp.dividends!)

        setIndicators(resp.indicators!)
        setFinancialData(resp.financialData)

        setQuotationItems(resp.quotes!)

        setRecommendation(resp.recommendations!)

        setQuotesStats(resp.quotesStats!)
      })
      .finally(() => setIsDataLoading(false))
  }

  const getStockValue = (item: any) => {
    return Number(item.closing)
  }

  const renderSummaryComponent = () => {
    return <StockDetailsSummary basicInfo={basicInfo} indicators={indicators} financialData={financialData} dividend={dividend} recommendation={recommendation} />
  }

  return (
    <BaseDetailsPage
      fromDate={fromDate}
      toDate={toDate}
      predefinedDateRange={predefinedDateRange}
      items={quotationItems!}
      quotesStats={quotesStats}
      resourceType={AssetTypeEnum.Stock}
      resourceId={companyDetails.companyShortCode!}
      summaryComponent={renderSummaryComponent()}
      dateRangeChanged={dateRangeChanged}
      getValue={getStockValue}
      isLoadingData={isDataLoading}
    />
  )
}

export default StockDetails
