import React, { useEffect } from 'react'
import styles from './BaseDetailsPage.module.scss'
import classNames from 'classnames'
import BaseDetailsChart from './BaseDetailsPageParts/BaseDetailsChart'
import BaseDetailsTable from './BaseDetailsPageParts/BaseDetailsTable'
import DateRangePredefined from '../../Components/DateRangePredefined/DateRangePredefined'
import { assetsApiClient } from '../../../../api-client/factory'
import FileSaver from 'file-saver'
import { formatDate } from '../../../../services/culture-service'
import { AssetTypeEnum, QuotesStats } from '../../../../api-client/generated'
import BaseDetailsQuotationsStats from './BaseDetailsPageParts/BaseDetailsQuotationsStats'
import AppLoader from '../../Components/AppLoader/AppLoader'
import { PredefinedDateRangeEnum } from '../../DateRange/PredefinedDateRangeEnum'

interface Props {
  fromDate?: Date
  toDate?: Date
  predefinedDateRange?: PredefinedDateRangeEnum
  items: any[]
  resourceType: AssetTypeEnum
  resourceId: string
  summaryComponent: React.ReactNode
  quotesStats?: QuotesStats // todo: zrobić wymagane
  dateRangeChanged: (startDate?: Date, endDate?: Date, predefinedDateRange?: PredefinedDateRangeEnum) => void
  getValue: (item: any) => number
  isLoadingData?: boolean
}

const BaseDetailsPage = (props: Props) => {
  const exportToExcel = () => {
    assetsApiClient()
      .apiAssetsResourceTypeResourceIdQuotesExcelGetRaw({
        resourceId: props.resourceId,
        resourceType: props.resourceType,
        from: props.fromDate,
        to: props.toDate
      })
      .then((resp) => resp.raw.blob())
      .then((blob) => {
        FileSaver.saveAs(
          blob,
          `${props.resourceId}__${formatDate(props.fromDate)}_${formatDate(
            props.toDate
          )}.xlsx`
        )
      })
  }

  const getTrends = () => {
    const result: any[] = []

    if (!!props.quotesStats?.trendWeek?.items) {
      const trendWeek = props.quotesStats.trendWeek

      result.push({
        name: 'trendWeek',
        items: trendWeek.items?.map((p: any) => ({
          dateTime: p.dateTime?.toISOString().split('T')[0],
          value: p.value
        }))
      })
    }

    if (!!props.quotesStats?.trendMonth) {
      const trendMonth = props.quotesStats.trendMonth

      result.push({
        name: 'trendMonth',
        items: trendMonth.items?.map((p) => ({
          dateTime: p.dateTime?.toISOString().split('T')[0],
          value: p.value
        }))
      })
    }

    return result
  }

  useEffect(() => {
    props.dateRangeChanged(props.fromDate, props.toDate, props.predefinedDateRange)
  }, [])

  const renderChart = () => {
    return props.isLoadingData
      ? <AppLoader />
      : <BaseDetailsChart
          items={props.items!}
          getValue={props.getValue}
          trends={getTrends()}
        />
  }

  const renderQuotationStats = () => {
    return props.isLoadingData
      ? <AppLoader />
      : <BaseDetailsQuotationsStats stats={props.quotesStats} />
  }

  const renderDetailsTable = () => {
    return props.isLoadingData
      ? <AppLoader />
      : <BaseDetailsTable items={props.items!} />
  }

  return (
    <div className={styles.baseDetailsGrid}>
      <div className={classNames(styles.box, styles.boxDetails)}>
        {props.isLoadingData ? <AppLoader /> : props.summaryComponent}
      </div>

      <div className={classNames(styles.box, styles.boxChart)}>
        {renderChart()}
      </div>

      <div className={classNames(styles.box, styles.boxDateChoose)}>
        <DateRangePredefined
          initPredefinedDateRange={props.predefinedDateRange}
          onChange={props.dateRangeChanged}
          initStartDate={props.fromDate}
          initEndDate={props.toDate}
        />
        <hr />
        <div className={styles.statTable}>
          {renderQuotationStats()}
        </div>
        <hr />
        <div className={styles.actionButtons}>
          <button onClick={exportToExcel} disabled={props.isLoadingData}>Export to Excel</button>
        </div>
      </div>

      <div className={classNames(styles.box, styles.boxTable)}>
        {renderDetailsTable()}
      </div>
    </div>
  )
}

export default BaseDetailsPage
