import { AgGridReact } from 'ag-grid-react'

interface Props {
  runRefreshData: any
  title: string
  className?: string
  boxTableWidth: number
  boxTableHeigth: number
  columns: any[]
  rows: any[]
}

const ImportStatusBox = (props: Props) => {
  return (
    <div className={props.className}>
      <h4>{props.title}</h4>
      <button onClick={props.runRefreshData}>Refresh data</button>
      <br />
      <div
        className="ag-theme-alpine"
        style={{ width: props.boxTableWidth, height: props.boxTableHeigth }}
      >
        <AgGridReact columnDefs={props.columns} rowData={props.rows} />
      </div>
    </div>
  )
}

export default ImportStatusBox
