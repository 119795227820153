import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { alertRuleApiFactory, homeApiFactory } from '../../api-client/factory'
import {
  AlertRuleTypeModel,
  ApiAlertRuleRulesPostRequest,
  AssetTypeEnum
} from '../../api-client/generated'
import AddAlertRuleFormConditionFactory from './AddAlertRuleFormConditionFactory'
import {
  showErrorToastr,
  showSuccessToastr
} from '../../services/toastr-notification-service'
import { useNavigate } from 'react-router-dom'
import UrlNavigationService from '../../services/url-service'
import ChooseAssetResource from './ChooseAssetResource'
import './AddAlertRuleForm.scss'

const AddAlertRuleForm = () => {
  const navigate = useNavigate()

  const [allAlertRuleTypes, setAllAlertRuleTypes] = useState<AlertRuleTypeModel[]>([])

  const [selectedAlertRuleType, setSelectedAlertRuleType] = useState<AlertRuleTypeModel | undefined>(undefined)

  const [ruleName, setRuleName] = useState<string>('')

  const [selectedAssetType, setSelectedAssetType] = useState<AssetTypeEnum | undefined>(undefined)
  const [selectedResourceId, setSelectedResourceId] = useState<number>(0)

  const [choosenFormAlertRuleCondition, setChoosenFormAlertRuleCondition] = useState<any>({})

  useEffect(() => {
    homeApiFactory()
      .apiHomeDictAllAlertRuleTypesGet()
      .then((response: AlertRuleTypeModel[]) => {
        const ruleTypes = [{}, ...response]
        setAllAlertRuleTypes(ruleTypes)
      })
  }, [])

  // todo: walidacja
  const validate = (values: any) => {
    const errors = {
      name: ''
    }

    if (!values.name) {
      errors.name = 'Required'
    }

    return errors
  }

  const onAlertRuleCondtionChange = (value: any) => {
    setChoosenFormAlertRuleCondition(value)
  }

  const submitForm = () => {
    const formValue: ApiAlertRuleRulesPostRequest = {
      createAlertRuleQuery: {
        name: ruleName,
        alertRuleType: selectedAlertRuleType?.alertRuleType,
        assetType: selectedAssetType,
        resourceId: selectedResourceId,
        conditions: choosenFormAlertRuleCondition
      }
    }

    alertRuleApiFactory()
      .apiAlertRuleRulesPost(formValue)
      .then(() => {
        showSuccessToastr('Alert rule added')

        navigate(UrlNavigationService.alertsRulesList())
      })
      .catch((error) => {
        console.error(error)
        showErrorToastr('Error: while adding')
      })
  }

  const initFormValue = {
    name: '',
    ruleType: 0,
    assetTypeId: 0,
    resourceName: 0
  }

  const selectRuleType = (value: any) => {
    const sel = allAlertRuleTypes.find((p) => p.id === Number(value.target.value))

    setSelectedAlertRuleType(sel)
  }

  return (
    <Formik
      initialValues={initFormValue}
      validate={validate}
      onSubmit={submitForm}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <h1>Add new alert</h1>
          <Container>
            <Row className="mar-b-1">
              <label>Name</label>
              <input onChange={(p) => setRuleName(p.target.value)} />
            </Row>
            <Row className="mar-b-1">
              <label>Rule Type</label>
              <select onChange={(p) => selectRuleType(p)}>
                {allAlertRuleTypes?.map((p) => (
                  <option key={p.id} value={p.id}>
                    {p.name}
                  </option>
                ))}
              </select>
            </Row>
            <Row className="mar-b-1">
              <ChooseAssetResource
                onAssetTypeChange={(p) => setSelectedAssetType(p)}
                onResourceChange={(p) => setSelectedResourceId(p)}
              />
            </Row>
            <Row>
              <hr />
              <AddAlertRuleFormConditionFactory
                onChange={onAlertRuleCondtionChange}
              />
            </Row>
            <Row>
              <button type="submit" onClick={submitForm}>
                Submit
              </button>
            </Row>
          </Container>
        </form>
      )}
    </Formik>
  )
}

export default AddAlertRuleForm
