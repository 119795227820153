import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import './MultiChart.scss'
import DateRangePredefined from '../../Common/Components/DateRangePredefined/DateRangePredefined'
import { Typeahead } from 'react-bootstrap-typeahead'
import { useEffect, useRef, useState } from 'react'
import {
  currencyApiFactory,
  homeApiFactory,
  nobleMetalsApiFactory,
  stockApiFactory,
  stockMarketIndexApiFactory
} from '../../../api-client/factory'
import {
  GetCompanyDetailsResponse,
  GetCurrentCurrencyDetailsQueryResponse,
  GetNobleMetalQuotationsQueryResponse,
  GetStockMarketIndexDetailsQueryDto,
  IdNamePairHierarchy
} from '../../../api-client/generated'
import {
  PredefinedDateRangeEnum,
  mapToDateRange
} from '../../Common/DateRange/PredefinedDateRangeEnum'
import { DateRangeModel } from '../../Common/DateRange/DateRangeModel'
import { formatDate } from '../../../services/culture-service'
import { Button } from 'react-bootstrap'
import { TypeEnum } from './TypeEnum'

interface QuotationItem {
  date: Date
  quote: number
}

const MultiChart = () => {
  const defaultDateRange = mapToDateRange(PredefinedDateRangeEnum.Quarter)

  const [items, setItems] = useState<any[]>([])
  const [selectedItems, setSelectedItems] = useState<any[]>([])
  const [addItem, setAddItem] = useState<any>({})
  const [dateRange, setDateRange] = useState<DateRangeModel>(defaultDateRange!)
  const [quotations, setQuotations] = useState<any[]>([])

  const defMinValue = 1_000_000
  const defMaxValue = 0

  const [minValue, setMinValue] = useState<number>(defMinValue)
  const [maxValue, setMaxValue] = useState<number>(defMaxValue)

  const typeaheadRef = useRef(null)

  useEffect(() => {
    homeApiFactory()
      .apiHomeDictAllAssetsGet()
      .then((response: IdNamePairHierarchy[]) => {
        const stocks = response[0].items?.map((p) => ({
          id: p.id,
          label: p.name,
          assetType: TypeEnum.Stock
        }))

        const currencies = response[1].items?.map((p) => ({
          id: p.id,
          label: p.name,
          assetType: TypeEnum.Currency
        }))

        const indexes = response[2].items?.map((p) => ({
          id: p.id,
          label: p.name,
          assetType: TypeEnum.MarketIndex
        }))

        const metals = response[3].items?.map((p) => ({
          id: p.id,
          label: p.name,
          assetType: TypeEnum.NobleMetal
        }))

        setItems([...stocks!, ...currencies!, ...indexes!, ...metals!])
      })
  }, [])

  const add = () => {
    // todo: endpoint pobieranie tylko notowań w zależności od UI
    // todo: integracja z wyborem dat
    // todo: inne znaczniki dla róznych AssetItems
    // todo: normalizacja danych, jakich check box czy 1:1 notowania czy znormalizowane

    fetchItemData(addItem)
  }

  const fetchItemData = (item: any) => {
    if (item.assetType === TypeEnum.Stock) {
      stockApiFactory()
        .apiStockCompanyIdGet({
          companyId: item.id,
          from: dateRange?.startDate,
          to: dateRange?.endDate
        })
        .then((response: GetCompanyDetailsResponse) => {
          const companyName = response.basicInfo?.companyName
          const stockQuotes = response?.quotes?.map((p) => ({
            date: p.date!,
            quote: p.closing!
          }))

          displayData(stockQuotes!, companyName!, item.assetType)
        })
    } else if (item.assetType === TypeEnum.Currency) {
      currencyApiFactory()
        .apiCurrencyCurrencyShortCodeGet({
          shortCode: item.id,
          fromDate: dateRange?.startDate,
          toDate: dateRange?.endDate
        })
        .then((response: GetCurrentCurrencyDetailsQueryResponse) => {
          const quotes = response.items?.map((p) => ({
            date: p.date!,
            quote: p.buyValue!
          }))

          displayData(quotes!, response.currencyName!, item.assetType)
        })
    } else if (item.assetType === TypeEnum.MarketIndex) {
      stockMarketIndexApiFactory()
        .apiStockMarketIndexNameGet({
          name: item.label,
          from: dateRange?.startDate,
          to: dateRange?.endDate
        })
        .then((response: GetStockMarketIndexDetailsQueryDto) => {
          const quotes = response.quotes?.map((p) => ({
            date: p.date!,
            quote: p.close!
          }))

          displayData(quotes!, item.label, item.assetType)
        })
    } else if (item.assetType === TypeEnum.NobleMetal) {
      nobleMetalsApiFactory()
        .apiNobleMetalGet({
          metal: item.label,
          from: dateRange?.startDate,
          to: dateRange?.endDate
        })
        .then((response: GetNobleMetalQuotationsQueryResponse) => {
          const quotes = response.items?.map((p) => ({
            date: p.date!,
            quote: p.quote!
          }))

          displayData(quotes!, item.label, item.assetType)
        })
    }
  }

  const displayData = (
    quotes: QuotationItem[],
    name: string,
    type: TypeEnum
  ) => {
    let quotationCopy = [...quotations]

    const key = `${type}_${name}`
    for (const quote of quotes) {
      let dateItem = quotationCopy.find(
        (p) => p.date === formatDate(quote.date)
      )
      if (!dateItem) {
        dateItem = {
          date: formatDate(quote.date)
        }

        quotationCopy.push(dateItem)
      }

      dateItem[key] = quote.quote
    }

    quotationCopy = quotationCopy.sort((a, b) => {
      return a?.date > b?.date ? 1 : -1
    })

    let min = minValue
    let max = maxValue
    for (const query of quotationCopy) {
      for (const property in query) {
        if (property !== 'date') {
          if (query[property] > max) {
            max = query[property]
          }

          if (query[property] < min) {
            min = query[property]
          }
        }
      }
    }

    setMinValue(min)
    setMaxValue(max)

    setQuotations(quotationCopy)

    const selectedItemsCopy = [...selectedItems]
    selectedItemsCopy.push({
      id: addItem.id,
      name,
      type,
      propertyName: key
    })

    setSelectedItems(selectedItemsCopy)

    const companyNameInput = typeaheadRef?.current as any
    companyNameInput?.clear()
  }

  const getColor = (a: any, index: number) => {
    let baseColor: any

    switch (a.type?.toUpperCase()) {
      case TypeEnum.Stock:
        baseColor = 0x150000 + 200 * index
        break
      case TypeEnum.Currency:
        baseColor = 0x550000 + 200 * index
        break
      case TypeEnum.MarketIndex:
        baseColor = 0x950000 + 200 * index
        break
      case TypeEnum.NobleMetal:
        baseColor = 0xA50000 + 200 * index
        break
    }

    return `#${baseColor.toString(16)}`
  }

  const renderLines = () => {
    return selectedItems.map((p, index) => {
      const color = getColor(p, index)
      return (
        <Line
          key={p.id}
          type="monotone"
          dataKey={p.propertyName}
          stroke={color}
        />
      )
    })
  }

  const remove = (item: any) => {
    const quotationCopy = [...quotations]
    quotationCopy.forEach((p) => delete p[item.propertyName])

    const selectedItemsCopy = selectedItems.filter((p) => p.id !== item.id)

    setQuotations(quotationCopy)
    setSelectedItems(selectedItemsCopy)
  }

  const clear = () => {
    setQuotations([])
    setSelectedItems([])

    setMinValue(defMinValue)
    setMaxValue(defMaxValue)
  }

  const changeDateRange = (start?: Date, end?: Date, predefinedDateRange?: PredefinedDateRangeEnum) => {
    if (!predefinedDateRange) {
      setDateRange({ startDate: start, endDate: end })
    } else {
      const dateRange = mapToDateRange(predefinedDateRange)

      setDateRange({ startDate: dateRange?.startDate, endDate: dateRange?.endDate })
    }  
  }

  const renderSelectedItems = () => {
    if (!selectedItems || selectedItems.length === 0) {
      return <></>
    }

    return (
      <>
        <span>Selected list</span>
        <br />
        <ul>
          {selectedItems.map((p, index) => {
            return (
              <li key={index} className="selectedItem">
                <button onClick={() => remove(p)}>Remove</button>
                <b>
                  {' '}
                  {p.name} ({p.type})
                </b>
              </li>
            )
          })}
        </ul>
        <hr />
        <div>
          <Button onClick={() => clear()}>Clear</Button>
        </div>
      </>
    )
  }

  return (
    <div className="grid-container">
      <div className="box-multi-chart baseBox">
        <LineChart
          // todo: obliczyć na pdostawie widoku
          width={1400}
          height={700}
          data={quotations}
        >
          <XAxis dataKey="date" />
          <YAxis domain={[minValue, maxValue]} />
          <Legend />
          <Tooltip />
          <CartesianGrid strokeDasharray="10 10" />
          {renderLines()}
        </LineChart>
      </div>

      <div className="box-filter baseBox">
        <DateRangePredefined
          initStartDate={defaultDateRange?.startDate}
          initEndDate={defaultDateRange?.endDate}
          initPredefinedDateRange={PredefinedDateRangeEnum.Custom}
          onChange={changeDateRange}
        />
      </div>

      <div className="box-series baseBox">
        <label>Add series to chart</label>
        <div className="typeahead">
          <Typeahead
            ref={typeaheadRef}
            id="add-item-multi-chart"
            options={items}
            onChange={(p: any[]) => {
              setAddItem(p[0])
            }}
          />
        </div>
        <div className="addButton">
          <button onClick={add}>Add</button>
        </div>
        <hr />
        {renderSelectedItems()}
      </div>
    </div>
  )
}

export default MultiChart
