import { useState } from 'react'
import { stockMarketIndexApiFactory } from '../../../../api-client/factory'
import BaseDetailsPage from '../../../Common/Base/BaseDetailsPage/BaseDetailsPage'
import MarketIndexDetailsSummary from './MarketIndexDetailsSummary'
import { useParams } from 'react-router-dom'
import { AssetTypeEnum, QuotesStats } from '../../../../api-client/generated'
import { DateTime } from 'luxon'
import { PredefinedDateRangeEnum } from '../../../Common/DateRange/PredefinedDateRangeEnum'

const MarketIndexDetails = () => {
  const { name } = useParams()

  const [rowData, setRowData] = useState<any[]>([])
  const [quotesStats, setQuotesStats] = useState<QuotesStats>()

  const [fromDate, setFromDate] = useState<Date | undefined>(DateTime.local().plus({ months: -3 }).toJSDate())
  const [toDate, setToDate] = useState<Date | undefined>(new Date())
  const [predefinedDateRange, setPredefinedDateRange] = useState<PredefinedDateRangeEnum | undefined>(PredefinedDateRangeEnum.Custom)

  const dateRangeChanged = (startDate?: Date, endDate?: Date, predefinedDateRange?: PredefinedDateRangeEnum) => {
    setFromDate(startDate)
    setToDate(endDate)
    setPredefinedDateRange(predefinedDateRange)

    stockMarketIndexApiFactory()
      .apiStockMarketIndexNameGet({
        name: name!,
        from: startDate,
        to: endDate
      })
      .then((response) => {
        setRowData(response.quotes!)
        setQuotesStats(response.queryStats)
      })
  }

  const getIndexValue = (item: any) => {
    return Number(item.close)
  }

  return (
    <BaseDetailsPage
      fromDate={fromDate}
      toDate={toDate}
      predefinedDateRange={predefinedDateRange}
      items={rowData}
      resourceType={AssetTypeEnum.MarketIndex}
      quotesStats={quotesStats}
      resourceId={name!}
      summaryComponent={<MarketIndexDetailsSummary indexName={name!} />}
      dateRangeChanged={dateRangeChanged}
      getValue={getIndexValue}
    />
  )
}

export default MarketIndexDetails
