/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StockRecommendationAction,
    StockRecommendationActionFromJSON,
    StockRecommendationActionFromJSONTyped,
    StockRecommendationActionToJSON,
} from './StockRecommendationAction';
import {
    TimestampedValue,
    TimestampedValueFromJSON,
    TimestampedValueFromJSONTyped,
    TimestampedValueToJSON,
} from './TimestampedValue';

/**
 * 
 * @export
 * @interface CompanyRatingRecommendationModel
 */
export interface CompanyRatingRecommendationModel {
    /**
     * 
     * @type {TimestampedValue}
     * @memberof CompanyRatingRecommendationModel
     */
    predictedQuotation?: TimestampedValue;
    /**
     * 
     * @type {string}
     * @memberof CompanyRatingRecommendationModel
     */
    issuer?: string | null;
    /**
     * 
     * @type {StockRecommendationAction}
     * @memberof CompanyRatingRecommendationModel
     */
    action?: StockRecommendationAction;
}

export function CompanyRatingRecommendationModelFromJSON(json: any): CompanyRatingRecommendationModel {
    return CompanyRatingRecommendationModelFromJSONTyped(json, false);
}

export function CompanyRatingRecommendationModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyRatingRecommendationModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'predictedQuotation': !exists(json, 'predictedQuotation') ? undefined : TimestampedValueFromJSON(json['predictedQuotation']),
        'issuer': !exists(json, 'issuer') ? undefined : json['issuer'],
        'action': !exists(json, 'action') ? undefined : StockRecommendationActionFromJSON(json['action']),
    };
}

export function CompanyRatingRecommendationModelToJSON(value?: CompanyRatingRecommendationModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'predictedQuotation': TimestampedValueToJSON(value.predictedQuotation),
        'issuer': value.issuer,
        'action': StockRecommendationActionToJSON(value.action),
    };
}

