import { FC } from 'react'
import DashboardAlertsBox from './boxes/AlertsBox/DashboardAlertsBox'
import DashboardBox from './boxes/DashboardBox'
import DashboardMyAssetsBox from './boxes/MyAssetsBox/DashboardMyAssetsBox'
import './Dashboard.scss'
import EconomicDataBox from './boxes/EconomicDataBox/EconomicDataBox'
import AssistantBox from './boxes/Assistant/AssistantBox'

const Dashboard: FC = () => {
  return (
    <>
      <main>
        <div className="grid-container-dashboard">
          <div className='box-asset'>
            <DashboardBox title='Wallet' >
              <DashboardMyAssetsBox />
            </DashboardBox>
          </div>
          <div className='box-alerts'>
            <DashboardBox title='Alerts'>
              <DashboardAlertsBox />
            </DashboardBox>
          </div>
          <div className='box-economic'>
            <DashboardBox title='Economic data' >
              <EconomicDataBox />
            </DashboardBox>
          </div>
          <div className='box-assistant'>
            <DashboardBox title='Assistant' >
              <AssistantBox />
            </DashboardBox>
          </div>
        </div>
      </main>
    </>)
}

export default Dashboard
