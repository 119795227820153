import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { AssetTypeEnum, GetNobleMetalQuotationsQueryResponse, NobleMetalEnum, QuotesStats } from '../../../api-client/generated'
import BaseDetailsPage from '../../Common/Base/BaseDetailsPage/BaseDetailsPage'
import { nobleMetalsApiFactory } from '../../../api-client/factory'
import { PredefinedDateRangeEnum } from '../../Common/DateRange/PredefinedDateRangeEnum'
import { DateTime } from 'luxon'

const NobleMetalsDetails = () => {
  const { id } = useParams()

  const [fromDate, setFromDate] = useState<Date | undefined>(DateTime.local().plus({ months: -3 }).toJSDate())
  const [toDate, setToDate] = useState<Date | undefined>(new Date())
  const [predefinedDateRange, setPredefinedDateRange] = useState<PredefinedDateRangeEnum | undefined>(PredefinedDateRangeEnum.Custom)

  const [rowData, setRowData] = useState<any[]>([])
  const [quotesStats, setQuotesStats] = useState<QuotesStats>()

  const mapToNobleMetalEnum = (p: string) => {
    if (p === 'Gold') {
      return NobleMetalEnum.Gold
    }
    // todo: names in enum instead of NUMBER_1
    return NobleMetalEnum.Gold
  }

  const dateRangeChanged = (startDate?: Date, endDate?: Date, predefinedDateRange?: PredefinedDateRangeEnum) => {
    setFromDate(startDate)
    setToDate(endDate)
    setPredefinedDateRange(predefinedDateRange)

    nobleMetalsApiFactory()
      .apiNobleMetalGet({
        metal: mapToNobleMetalEnum(id!),
        from: startDate,
        to: endDate
      })
      .then((response: GetNobleMetalQuotationsQueryResponse) => {
        const items = response.items?.map(p => ({
          date: p.date,
          quote: p.quote
        }))

        setRowData(items!)
        setQuotesStats(response.queryStats)
      })
  }

  return <BaseDetailsPage
    fromDate={fromDate}
    toDate={toDate}
    predefinedDateRange={predefinedDateRange}
    items={rowData}
    resourceType={AssetTypeEnum.NobleMetal}
    quotesStats={quotesStats}
    resourceId={id!}
    summaryComponent={<h1>{id?.toUpperCase()}</h1>}
    dateRangeChanged={dateRangeChanged}
    getValue={(p) => p.quote}
  />
}

export default NobleMetalsDetails
